export const options = [
  {
    id: 1,
    value: "College Student",
    databaseName: "college_students",
    subValue: [
      { id: 2, value: "Freshman", databaseName: "freshman" },
      { id: 3, value: "Sophomore", databaseName: "sophomores" },
      { id: 4, value: "Junior Senior", databaseName: "juniors_seniors" },
      { id: 5, value: "Graduate Student", databaseName: "graduate_students" },
    ],
  },
  {
    id: 6,
    value: "Professional",
    databaseName: "professionals",
    subValue: [
      { id: 7, value: "Freelancer", databaseName: "freelancers" },
      { id: 8, value: "Middle Manager", databaseName: "middle_managers" },
      { id: 9, value: "Recent Graduate", databaseName: "recent_graduates" },
      { id: 10, value: "Rising Executive", databaseName: "rising_executives" },
      { id: 11, value: "Senior Executive", databaseName: "senior_executives" },
    ],
  },
  {
    id: 12,
    value: "Business Owner",
    databaseName: "business_owners",
    subValue: [
      { id: 13, value: "Founder", databaseName: "founders" },
      { id: 14, value: "Mature", databaseName: "mature" },
      { id: 15, value: "Startup", databaseName: "startups" },
    ],
  },
  {
    id: 16,
    value: "Board Member",
    databaseName: "board_members",
    subValue: [
      {
        id: 17,
        value: "Board of Directors",
        databaseName: "board_of_directors",
      },
      { id: 18, value: "Diverse Company", databaseName: "diverse_company" },
      { id: 19, value: "Board Advisor", databaseName: "board_associates" },
    ],
  },
]

export const genderOptions = [
  {
    id: 1,
    value: "Prefer not to say",
    databaseName: "not_applicable_gender",
  },
  {
    id: 2,
    value: "Male",
    databaseName: "male",
  },
  {
    id: 3,
    value: "Female",
    databaseName: "female",
  },
  {
    id: 4,
    value: "Non-binary",
    databaseName: "non_binary",
  },
  {
    id: 5,
    value: "Genderqueer",
    databaseName: "genderqueer",
  },
  {
    id: 6,
    value: "Transgender",
    databaseName: "transgender",
  },
  {
    id: 7,
    value: "Genderfluid",
    databaseName: "genderfluid",
  },
  {
    id: 8,
    value: "Agender",
    databaseName: "agender",
  },
  {
    id: 9,
    value: "Two-Spirit",
    databaseName: "two_spirit",
  },
  {
    id: 10,
    value: "Other",
    databaseName: "other",
  }
]

export const raceOptions = [
  {
    id: 1,
    value: "N/A",
    databaseName: "not_applicable_race",
  },
  {
    id: 2,
    value: "Black/African-American",
    databaseName: "black_african_american",
  },
  {
    id: 3,
    value: "American Indian/Alaskan Native",
    databaseName: "american_indian",
  },
  {
    id: 4,
    value: "Asian",
    databaseName: "asian",
  },
  {
    id: 5,
    value: "Native Hawaiian/Pacific Islander",
    databaseName: "native_hawaiian",
  },
  {
    id: 6,
    value: "Hispanic/Latino",
    databaseName: "hispanic",
  },
  {
    id: 7,
    value: "White",
    databaseName: "white",
  },
]

export const jobTitleOptions = [
  { id: 1, value: "Manager", databaseName: "Manager" },
  { id: 2, value: "Senior Manager", databaseName: "Senior Manager" },
  { id: 3, value: "Chief Executive Officer", databaseName: "Chief Executive Officer" },
  { id: 4, value: "Chief Financial Officer", databaseName: "Chief Financial Officer" },
  { id: 5, value: "Chief Scientific Officer", databaseName: "Chief Scientific Officer" },
  { id: 6, value: "Chief Operating Officer", databaseName: "Chief Operating Officer" },
  { id: 7, value: "Chief Development Officer", databaseName: "Chief Development Officer" },
  { id: 8, value: "Chief Lending Officer", databaseName: "Chief Lending Officer" },
  { id: 9, value: "Officer", databaseName: "Officer" },
  { id: 10, value: "Board Member", databaseName: "Board Member" },
  { id: 11, value: "Chairman", databaseName: "Chairman" },
  { id: 12, value: "Chancellor", databaseName: "Chancellor" },
  { id: 13, value: "Associate", databaseName: "Associate" },
  { id: 14, value: "Director", databaseName: "Director" },
  { id: 15, value: "Owner", databaseName: "Owner" },
  { id: 16, value: "Executive", databaseName: "Executive" },
  { id: 17, value: "Partner", databaseName: "Partner" },
  { id: 18, value: "Developer", databaseName: "Developer" },
  { id: 19, value: "Administrator", databaseName: "Administrator" },
  { id: 20, value: "Mayor", databaseName: "Mayor" },
  { id: 21, value: "President", databaseName: "President" },
  { id: 22, value: "Vice President", databaseName: "Vice President" },
  { id: 23, value: "Physician", databaseName: "Physician" },
  { id: 24, value: "Superintendent", databaseName: "Superintendent" },
  { id: 25, value: "Coach", databaseName: "Coach" },
  { id: 26, value: "Teacher", databaseName: "Teacher" },
  { id: 27, value: "Lead", databaseName: "Lead" },
  { id: 28, value: "Judge", databaseName: "Judge" },
  { id: 29, value: "Other", databaseName: "Other" },
]
export const professionOptions = [
  {
    id: 1,
    value: "Academia",
    databaseName: "academia",
  },
  {
    id: 2,
    value: "Agriculture & Environmental Services",
    databaseName: "agriculture_environmental_services",
  },
  {
    id: 3,
    value: "Business & Finance",
    databaseName: "business_finance",
  },
  {
    id: 4,
    value: "Construction",
    databaseName: "construction",
  },
  {
    id: 5,
    value: "Creative Arts & Design",
    databaseName: "creative_arts_design",
  },
  {
    id: 6,
    value: "Education & Training",
    databaseName: "education_training",
  },
  {
    id: 7,
    value: "Engineering & Technology",
    databaseName: "engineering_technology",
  },
  {
    id: 8,
    value: "Entertainment & Film",
    databaseName: "entertainment_film",
  },
  {
    id: 9,
    value: "Government & Public Administration",
    databaseName: "government_public_admin",
  },
  {
    id: 10,
    value: "Health & Medicine",
    databaseName: "health_medicine",
  },
  {
    id: 11,
    value: "Healthcare",
    databaseName: "healthcare",
  },
  {
    id: 12,
    value: "Hospitality & Tourism",
    databaseName: "hospitality_tourism",
  },
  {
    id: 13,
    value: "Law & Legal Professions",
    databaseName: "law_legal_professions",
  },
  {
    id: 14,
    value: "Law Enforcement",
    databaseName: "law_enforcement",
  },
  {
    id: 15,
    value: "Manufacturing & Production",
    databaseName: "manufacturing_production",
  },
  {
    id: 16,
    value: "Media & Marketing",
    databaseName: ":media_communications",
  },
  {
    id: 17,
    value: "Public Safety & Defense",
    databaseName: "public_safety_defense",
  },
  {
    id: 18,
    value: "Sales & Customer Service",
    databaseName: "sales_customer_service",
  },
  {
    id: 19,
    value: "Science & Research",
    databaseName: "science_research",
  },
  {
    id: 20,
    value: "Sports & Recreation",
    databaseName: "sports_recreation",
  },
  {
    id: 21,
    value: "Transportation & Logistics",
    databaseName: "transportation_logistics",
  }
]

export const experienceOptions = [
  {
    id: 1,
    value: "Entry",
    databaseName: "entry",
  },
  {
    id: 2,
    value: "Mid",
    databaseName: "mid",
  },
  {
    id: 3,
    value: "Senior",
    databaseName: "senior",
  },
  {
    id: 4,
    value: "Executive",
    databaseName: "executive",
  },
  {
    id: 5,
    value: "Expert/Specialist",
    databaseName: "expert_specialist",
  },
  {
    id: 5,
    value: "Board Member",
    databaseName: "board_member",
  },
]

export const industryOptions = [
  {
    id: 0,
    value: "Administrative Services",
    databaseName: "administrative_services",
  },
  {
    id: 1,
    value: "Airlines",
    databaseName: "airlines",
  },
  { id: 2, value: "Advertising", databaseName: "advertising" },
  { id: 3, value: "Apparel", databaseName: "apparel" },
  {
    id: 4,
    value: "Arts, Entertainment & Recreation",
    databaseName: "arts_entertainment_and_recreation",
  },
  { id: 5, value: "Beauty & Cosmetics", databaseName: "beauty" },
  { id: 6, value: "Broadcasting (except Internet)", databaseName: "broadcasting" },
  { id: 7, value: "Construction", databaseName: "construction_industry" },
  {
    id: 8,
    value: "Data processing, hosting & related services",
    databaseName: "data_processing_hosting_and_related_services",
  },
  { id: 9, value: "Education", databaseName: "education" },
  {
    id: 10,
    value: "Financial Services & Insurance",
    databaseName: "financial_services_and_insurance",
  },
  {
    id: 11,
    value: "Food Services",
    databaseName: "food_services",
  },
  {
    id: 12,
    value: "Governance & Strategic Development",
    databaseName: "governance_strategic_development",
  },
  { id: 13, value: "Government", databaseName: "government" },
  { id: 14, value: "Healthcare", databaseName: "healthcare_industry" },
  { id: 15, value: "Human Resources", databaseName: "human_resources" },
  { id: 16, value: "Infrastructure", databaseName: "infrastructure" },
  {
    id: 18,
    value: "Information Technology & Digital",
    databaseName: "it_digital",
  },
  {
    id: 19,
    value: "Legal",
    databaseName: "legal",
  },
  {
    id: 20,
    value: "Leisure & Hospitality",
    databaseName: "leisure_and_hospitality",
  },
  {
    id: 21,
    value: "3PL & Distribution",
    databaseName: "distribution",
  },
  {
    id: 22,
    value: "Product Development & Manufacturing",
    databaseName: "manufacturing",
  },
  {
    id: 23,
    value: "Management of Companies & Enterprise",
    databaseName: "management_of_companies_and_enterprises",
  },
  { id: 26, value: "Marketing & Communications", databaseName: "marketing" },
  { id: 27, value: "Media", databaseName: "media" },
  {
    id: 29,
    value: "Professional Business Services & Consulting",
    databaseName: "professional_and_business_services",
  },
  {
    id: 30,
    value: "Professional, Scientific & Technical Services",
    databaseName: "professional_scientific_and_technical_services",
  },
  { id: 31, value: "Public Relations", databaseName: "public_relations" },
  { id: 32, value: "Real Estate", databaseName: "real_estate" },
  { id: 33, value: "Retail Trade", databaseName: "retail_trade" },
  {
    id: 34,
    value: "Software Development",
    databaseName: "software_development",
  },
  { id: 35, value: "Technology", databaseName: "technology" },
  { id: 36, value: "Telecommunications", databaseName: "telecommunications" },
  { id: 38, value: "Transportation", databaseName: "transportation" },
  { id: 39, value: "Wholesale Trade", databaseName: "wholesale_trade" },
]

export const marketOptions = [
  {
    id: 1,
    value: "United States",
    databaseName: "US",
    source: "https://gist.github.com/mshafrir/2646763",
    subValue: [
      { id: "AL", databaseName: "AL", name: "Alabama" },
      { id: "AK", databaseName: "AK", name: "Alaska" },
      { id: "AZ", databaseName: "AZ", name: "Arizona" },
      { id: "AR", databaseName: "AR", name: "Arkansas" },
      { id: "CA", databaseName: "CA", name: "California" },
      { id: "CO", databaseName: "CO", name: "Colorado" },
      { id: "CT", databaseName: "CT", name: "Connecticut" },
      { id: "DE", databaseName: "DE", name: "Delaware" },
      { id: "DC", databaseName: "DC", name: "District Of Columbia" },
      { id: "FL", databaseName: "FL", name: "Florida" },
      { id: "GA", databaseName: "GA", name: "Georgia" },
      { id: "ID", databaseName: "ID", name: "Idaho" },
      { id: "IL", databaseName: "IL", name: "Illinois" },
      { id: "IN", databaseName: "IN", name: "Indiana" },
      { id: "IA", databaseName: "IA", name: "Iowa" },
      { id: "KS", databaseName: "KS", name: "Kansas" },
      { id: "KY", databaseName: "KY", name: "Kentucky" },
      { id: "LA", databaseName: "LA", name: "Louisiana" },
      { id: "ME", databaseName: "ME", name: "Maine" },
      { id: "MD", databaseName: "MD", name: "Maryland" },
      { id: "MA", databaseName: "MA", name: "Massachusetts" },
      { id: "MI", databaseName: "MI", name: "Michigan" },
      { id: "MN", databaseName: "MN", name: "Minnesota" },
      { id: "MS", databaseName: "MS", name: "Mississippi" },
      { id: "MO", databaseName: "MO", name: "Missouri" },
      { id: "MT", databaseName: "MT", name: "Montana" },
      { id: "NE", databaseName: "NE", name: "Nebraska" },
      { id: "NV", databaseName: "NV", name: "Nevada" },
      { id: "NH", databaseName: "NH", name: "New Hampshire" },
      { id: "NJ", databaseName: "NJ", name: "New Jersey" },
      { id: "NM", databaseName: "NM", name: "New Mexico" },
      { id: "NY", databaseName: "NY", name: "New York" },
      { id: "NC", databaseName: "NC", name: "North Carolina" },
      { id: "ND", databaseName: "ND", name: "North Dakota" },
      { id: "OH", databaseName: "OH", name: "Ohio" },
      { id: "OK", databaseName: "OK", name: "Oklahoma" },
      { id: "OR", databaseName: "OR", name: "Oregon" },
      { id: "PA", databaseName: "PA", name: "Pennsylvania" },
      { id: "PR", databaseName: "PR", name: "Puerto Rico" },
      { id: "RI", databaseName: "RI", name: "Rhode Island" },
      { id: "SC", databaseName: "SC", name: "South Carolina" },
      { id: "SD", databaseName: "SD", name: "South Dakota" },
      { id: "TN", databaseName: "TN", name: "Tennessee" },
      { id: "TX", databaseName: "TX", name: "Texas" },
      { id: "UT", databaseName: "UT", name: "Utah" },
      { id: "VT", databaseName: "VT", name: "Vermont" },
      { id: "VI", databaseName: "VI", name: "Virgin Islands" },
      { id: "VA", databaseName: "VA", name: "Virginia" },
      { id: "WA", databaseName: "WA", name: "Washington" },
      { id: "WV", databaseName: "WV", name: "West Virginia" },
      { id: "WI", databaseName: "WI", name: "Wisconsin" },
      { id: "WY", databaseName: "WY", name: "Wyoming" },
      { id: "HI", databaseName: "HI", name: "Hawaii" },
    ],
  },
  {
    id: 2,
    value: "Canada",
    databaseName: "CA",
    subValue: [],
  },
  {
    id: 3,
    value: "Europe",
    databaseName: "EU",
    source:
      "https://gist.github.com/jim-at-jibba/25fbdb561e927eeb9376a1f49db3907e",
    subValue: [
      { id: "AD", databaseName: "AD", name: "Andorra" },
      { id: "AL", databaseName: "AL", name: "Albania" },
      { id: "AT", databaseName: "AT", name: "Austria" },
      { id: "BA", databaseName: "BA", name: "Bosnia and Herzegovina" },
      { id: "BE", databaseName: "BE", name: "Belgium" },
      { id: "BG", databaseName: "BG", name: "Bulgaria" },
      { id: "BY", databaseName: "BY", name: "Belarus" },
      { id: "CH", databaseName: "CH", name: "Switzerland" },
      { id: "CY", databaseName: "CY", name: "Cyprus" },
      { id: "CZ", databaseName: "CZ", name: "Czech Republic" },
      { id: "DE", databaseName: "DE", name: "Germany" },
      { id: "DK", databaseName: "DK", name: "Denmark" },
      { id: "EE", databaseName: "EE", name: "Estonia" },
      { id: "ES", databaseName: "ES", name: "Spain" },
      { id: "FI", databaseName: "FI", name: "Finland" },
      { id: "FO", databaseName: "FO", name: "Faroe Islands" },
      { id: "FR", databaseName: "FR", name: "France" },
      { id: "GB", databaseName: "GB", name: "United Kingdom" },
      { id: "GR", databaseName: "GR", name: "Greece" },
      { id: "HR", databaseName: "HR", name: "Croatia" },
      { id: "HU", databaseName: "HU", name: "Hungary" },
      { id: "HS", databaseName: "HS", name: "Holy See" },
      { id: "IE", databaseName: "IE", name: "Ireland" },
      { id: "IC", databaseName: "IC", name: "Iceland" },
      { id: "IT", databaseName: "IT", name: "Italy" },
      { id: "LI", databaseName: "LI", name: "Liechtenstein" },
      { id: "LT", databaseName: "LT", name: "Lithuania" },
      { id: "LU", databaseName: "LU", name: "Luxembourg" },
      { id: "LV", databaseName: "LV", name: "Latvia" },
      { id: "MC", databaseName: "MC", name: "Monaco" },
      { id: "MD", databaseName: "MD", name: "Moldova" },
      { id: "MK", databaseName: "MK", name: "Macedonia" },
      { id: "MNN", databaseName: "MNN", name: "Montenegro" },
      { id: "MT", databaseName: "MT", name: "Malta" },
      { id: "NL", databaseName: "NL", name: "Netherlands" },
      { id: "NO", databaseName: "NO", name: "Norway" },
      { id: "MNK", databaseName: "MNK", name: "North Macedonia" },
      { id: "PL", databaseName: "PL", name: "Poland" },
      { id: "PT", databaseName: "PT", name: "Portugal" },
      { id: "RO", databaseName: "RO", name: "Romania" },
      { id: "RU", databaseName: "RU", name: "Russia" },
      { id: "SE", databaseName: "SE", name: "Sweden" },
      { id: "SI", databaseName: "SI", name: "Slovenia" },
      { id: "SW", databaseName: "SW", name: "Serbia" },
      { id: "SK", databaseName: "SK", name: "Slovakia" },
      { id: "SM", databaseName: "SM", name: "San Marino" },
      { id: "UA", databaseName: "UA", name: "Ukraine" },
      { id: "VA", databaseName: "VA", name: "Vatican City State" },
    ],
  },
  {
    id: 4,
    value: "Asia-Pacific",
    databaseName: "AP",
    source: "https://worldpopulationreview.com/country-rankings/apac-countries",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AZE", databaseName: "AZE", name: "Azerbaijan" },
      { id: "AS", databaseName: "AS", name: "American Samoa" },
      { id: "AM", databaseName: "AM", name: "Armenia" },
      { id: "AU", databaseName: "AU", name: "Australia" },
      { id: "BD", databaseName: "BD", name: "Bangladesh" },
      { id: "BT", databaseName: "BT", name: "Bhutan" },
      { id: "BN", databaseName: "BN", name: "Brunei" },
      { id: "KH", databaseName: "KH", name: "Cambodia" },
      { id: "CN", databaseName: "CN", name: "China" },
      { id: "CK", databaseName: "CK", name: "Cook Islands" },
      { id: "FJ", databaseName: "FJ", name: "Fiji" },
      { id: "PF", databaseName: "PF", name: "French Polynesia" },
      { id: "GU", databaseName: "GU", name: "Guam" },
      { id: "IN", databaseName: "IN", name: "India" },
      { id: "ID", databaseName: "ID", name: "Indonesia" },
      { id: "JP", databaseName: "JP", name: "Japan" },
      { id: "KI", databaseName: "KI", name: "Kiribati" },
      { id: "LA", databaseName: "LA", name: "Laos" },
      { id: "MY", databaseName: "MY", name: "Malaysia" },
      { id: "MV", databaseName: "MV", name: "Maldives" },
      { id: "MH", databaseName: "MH", name: "Marshall Islands" },
      { id: "FM", databaseName: "FM", name: "Micronesia" },
      { id: "MN", databaseName: "MN", name: "Mongolia" },
      { id: "MM", databaseName: "MM", name: "Myanmar" },
      { id: "NR", databaseName: "NR", name: "Nauru" },
      { id: "NP", databaseName: "NP", name: "Nepal" },
      { id: "NC", databaseName: "NC", name: "New Caledonia" },
      { id: "NZ", databaseName: "NZ", name: "New Zealand" },
      { id: "NU", databaseName: "NU", name: "Niue" },
      { id: "KP", databaseName: "KP", name: "North Korea" },
      { id: "MP", databaseName: "MP", name: "Northern Mariana Islands" },
      { id: "PK", databaseName: "PK", name: "Pakistan" },
      { id: "PW", databaseName: "PW", name: "Palau" },
      { id: "PG", databaseName: "PG", name: "Papua New Guinea" },
      { id: "PH", databaseName: "PH", name: "Philippines" },
      { id: "RU", databaseName: "RU", name: "Russia" },
      { id: "WS", databaseName: "WS", name: "Samoa" },
      { id: "SG", databaseName: "SG", name: "Singapore" },
      { id: "SB", databaseName: "SB", name: "Solomon Islands" },
      { id: "KR", databaseName: "KR", name: "South Korea" },
      { id: "LK", databaseName: "LK", name: "Sri Lanka" },
      { id: "TW", databaseName: "TW", name: "Taiwan" },
      { id: "TJ", databaseName: "TJ", name: "Tajikistan" },
      { id: "TH", databaseName: "TH", name: "Thailand" },
      { id: "TL", databaseName: "TL", name: "Timor Leste" },
      { id: "TK", databaseName: "TK", name: "Tokelau" },
      { id: "TU", databaseName: "TU", name: "Turkmenistan" },
      { id: "TO", databaseName: "TO", name: "Tonga" },
      { id: "TV", databaseName: "TV", name: "Tuvalu" },
      { id: "KZ", databaseName: "KZ", name: "Kazakhstan" },
      { id: "KG", databaseName: "KG", name: "Kyrgyzstan" },
      { id: "VU", databaseName: "VU", name: "Vanuatu" },
      { id: "VN", databaseName: "VN", name: "Vietnam" },
      { id: "WF", databaseName: "WF", name: "Wallis And Futuna" },
      { id: "UZ", databaseName: "UZ", name: "Uzbekistan" },
    ],
  },
  {
    id: 5,
    value: "Latin America",
    databaseName: "LA",
    source:
      "https://www.britannica.com/topic/list-of-countries-in-Latin-America-2061416",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AR", databaseName: "AR", name: "Argentina" },
      { id: "BZ", databaseName: "BZ", name: "Belize" },
      { id: "BO", databaseName: "BO", name: "Bolivia" },
      { id: "BM", databaseName: "BM", name: "Bahamas" },
      { id: "BS", databaseName: "BS", name: "Barbados" },
      { id: "BR", databaseName: "BR", name: "Brazil" },
      { id: "BR", databaseName: "BR", name: "Brazil" },
      { id: "CL", databaseName: "CL", name: "Chile" },
      { id: "CO", databaseName: "CO", name: "Colombia" },
      { id: "CR", databaseName: "CR", name: "Costa Rica" },
      { id: "CU", databaseName: "CU", name: "Cuba" },
      { id: "DO", databaseName: "DO", name: "Dominican Republic" },
      { id: "EC", databaseName: "EC", name: "Ecuador" },
      { id: "SV", databaseName: "SV", name: "El Salvador" },
      { id: "GF", databaseName: "GF", name: "French Guiana" },
      { id: "GP", databaseName: "GP", name: "Guadeloupe" },
      { id: "GT", databaseName: "GT", name: "Guatemala" },
      { id: "GY", databaseName: "GY", name: "Guyana" },
      { id: "HT", databaseName: "HT", name: "Haiti" },
      { id: "HN", databaseName: "HN", name: "Honduras" },
      { id: "MQ", databaseName: "MQ", name: "Martinique" },
      { id: "MX", databaseName: "MX", name: "Mexico" },
      { id: "NI", databaseName: "NI", name: "Nicaragua" },
      { id: "PA", databaseName: "PA", name: "Panama" },
      { id: "PY", databaseName: "PY", name: "Paraguay" },
      { id: "PE", databaseName: "PE", name: "Peru" },
      { id: "JM", databaseName: "JM", name: "Jamaica" },
      { id: "PR", databaseName: "PR", name: "Puerto Rico" },
      { id: "BL", databaseName: "BL", name: "Saint-Barthlemy" },
      { id: "MF", databaseName: "MF", name: "Saint-Martin" },
      { id: "VG", databaseName: "VG", name: "St. Vincent & Grenadines" },
      { id: "KN", databaseName: "KN", name: "Saint Kitts & Nevis" },
      { id: "SR", databaseName: "SR", name: "Suriname" },
      { id: "TT", databaseName: "TT", name: "Trinidad and Tobago" },
      { id: "UY", databaseName: "UY", name: "Uruguay" },
      { id: "VE", databaseName: "VE", name: "Venezuela" },
    ],
  },
  {
    id: 6,
    value: "Middle East",
    databaseName: "ME",
    source:
      "https://worldpopulationreview.com/country-rankings/middle-east-countries",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AG", databaseName: "AG", name: "Afghanistan" },
      { id: "BH", databaseName: "BH", name: "Bahrain" },
      { id: "CY", databaseName: "CY", name: "Cyprus" },
      { id: "EG", databaseName: "EG", name: "Egypt" },
      { id: "IR", databaseName: "IR", name: "Iran" },
      { id: "IQ", databaseName: "IQ", name: "Iraq" },
      { id: "IL", databaseName: "IL", name: "Israel" },
      { id: "JO", databaseName: "JO", name: "Jordan" },
      { id: "KW", databaseName: "KW", name: "Kuwait" },
      { id: "LB", databaseName: "LB", name: "Lebanon" },
      { id: "OM", databaseName: "OM", name: "Oman" },
      { id: "PS", databaseName: "PS", name: "Palestine" },
      { id: "QA", databaseName: "QA", name: "Qatar" },
      { id: "SA", databaseName: "SA", name: "Saudi Arabia" },
      { id: "SY", databaseName: "SY", name: "Syria" },
      { id: "AE", databaseName: "AE", name: "The United Arab Emirates" },
      { id: "TR", databaseName: "TR", name: "Turkey" },
      { id: "YE", databaseName: "YE", name: "Yemen" },
    ],
  },
  {
    id: 7,
    value: "Africa",
    databaseName: "AF",
    source: "https://www.countries-ofthe-world.com/countries-of-africa.html",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "DZ", databaseName: "DZ", name: "Algeria" },
      { id: "AO", databaseName: "AO", name: "Angola" },
      { id: "BJ", databaseName: "BJ", name: "Benin" },
      { id: "BW", databaseName: "BW", name: "Botswana" },
      { id: "BF", databaseName: "BF", name: "Burkina Faso" },
      { id: "BI", databaseName: "BI", name: "Burundi" },
      { id: "CV", databaseName: "CV", name: "Cabo Verde" },
      { id: "CM", databaseName: "CM", name: "Cameroon" },
      { id: "CF", databaseName: "CF", name: "Central African Republic" },
      { id: "TD", databaseName: "TD", name: "Chad" },
      { id: "KM", databaseName: "KM", name: "Comoros" },
      {
        id: "CD",
        databaseName: "CD",
        name: "Congo, Democratic Republic of the",
      },
      { id: "CG", databaseName: "CG", name: "Congo, Republic of the" },
      { id: "CI", databaseName: "CI", name: "Cote d'Ivoire" },
      { id: "DJ", databaseName: "DJ", name: "Djibouti" },
      { id: "EG", databaseName: "EG", name: "Egypt" },
      { id: "GQ", databaseName: "GQ", name: "Equatorial Guinea" },
      { id: "ER", databaseName: "ER", name: "Eritrea" },
      { id: "SZ", databaseName: "SZ", name: "Eswatini" },
      { id: "ET", databaseName: "ET", name: "Ethiopia" },
      { id: "GA", databaseName: "GA", name: "Gabon" },
      { id: "GM", databaseName: "GM", name: "Gambia" },
      { id: "GH", databaseName: "GH", name: "Ghana" },
      { id: "GN", databaseName: "GN", name: "Guinea" },
      { id: "GW", databaseName: "GW", name: "Guinea-Bissau" },
      { id: "KE", databaseName: "KE", name: "Kenya" },
      { id: "LS", databaseName: "LS", name: "Lesotho" },
      { id: "LR", databaseName: "LR", name: "Liberia" },
      { id: "LY", databaseName: "LY", name: "Libya" },
      { id: "MG", databaseName: "MG", name: "Madagascar" },
      { id: "MW", databaseName: "MW", name: "Malawi" },
      { id: "ML", databaseName: "ML", name: "Mali" },
      { id: "MR", databaseName: "MR", name: "Mauritania" },
      { id: "MU", databaseName: "MU", name: "Mauritius" },
      { id: "MA", databaseName: "MA", name: "Morocco" },
      { id: "MZ", databaseName: "MZ", name: "Mozambique" },
      { id: "NA", databaseName: "NA", name: "Namibia" },
      { id: "NE", databaseName: "NE", name: "Niger" },
      { id: "NG", databaseName: "NG", name: "Nigeria" },
      { id: "RW", databaseName: "RW", name: "Rwanda" },
      { id: "ST", databaseName: "ST", name: "Sao Tome and Principe" },
      { id: "SN", databaseName: "SN", name: "Senegal" },
      { id: "SC", databaseName: "SC", name: "Seychelles" },
      { id: "SL", databaseName: "SL", name: "Sierra Leone" },
      { id: "SO", databaseName: "SO", name: "Somalia" },
      { id: "ZA", databaseName: "ZA", name: "South Africa" },
      { id: "SS", databaseName: "SS", name: "South Sudan" },
      { id: "SD", databaseName: "SD", name: "Sudan" },
      { id: "TZ", databaseName: "TZ", name: "Tanzania" },
      { id: "TG", databaseName: "TG", name: "Togo" },
      { id: "TN", databaseName: "TN", name: "Tunisia" },
      { id: "UG", databaseName: "UG", name: "Uganda" },
      { id: "ZM", databaseName: "ZM", name: "Zambia" },
      { id: "ZW", databaseName: "ZW", name: "Zimbabwe" },
    ],
  },
]
