import React, { useReducer, useEffect } from "react"
import classNames from "classnames"
import ProfessionDropdown from "./wrappers/ProfessionDropdown"
import IndustryDropdown from "./wrappers/IndustryDropdown"
import ExperienceDropdown from "./wrappers/ExperienceDropdown"
import JobTitleAutocomplete from "./wrappers/JobTitleAutocomplete"
import LocationAutocomplete from "./wrappers/LocationAutocomplete"
import SkillAutocomplete from "./wrappers/SkillAutocomplete"
import RoleTypeDropdown from "./wrappers/RoleTypeDropdown"
import ActivityTypeDropdown from "./wrappers/ActivityTypeDropdown"
import GenderTypeDropdown from "./wrappers/GenderTypeDropdown"
import RaceTypeDropdown from "./wrappers/RaceTypeDropdown"
import CompanyRoleTypeDropdown from "./wrappers/CompanyRoleTypeDropdown"
import CompanyActivityTypeDropdown from "./wrappers/CompanyActivityTypeDropdown"
import CompanyIndustryTypeDropdown from "./wrappers/CompanyIndustryTypeDropdown"
import CompanyDiverseTypeDropdown from "./wrappers/CompanyDiverseTypeDropdown"
import CompanyClassificationMultiSelectDropdown from "./wrappers/CompanyClassificationMultiSelectDropdown"
import CompanyMarketTypeDropdown from "./wrappers/CompanyMarketTypeDropdown"
import CategoryTypeDropdown from "./wrappers/CategoryTypeDropdown";
import FreelancersCountryDropdown from "./wrappers/FreelancersCountryDropdown"
import RadioButton from "../../RadioButton/RadioButton"
import Button from "../../Button/Button"
import { ReactComponent as ArrowTopIcon } from "../../../images/icons/arrow-top.svg"
import { reducer, initState } from "./useReducer/reducer"
import {
  toggleCollapse,
  openIndividualSubForm,
  openCompanySubForm,
  setWholeState,
  resetForm,
  openFreelancersSubForm,
} from "./useReducer/actionCreators"
import styles from "./index.module.scss"
import features from "../../../features"
import CompanyCertifiedTypeDropdown from "./wrappers/CompanyCertifiedTypeDropdown";
import CompanyTypeDropdown from "./wrappers/CompanyTypeDropdown";
import LevelTypeDropdown from "./wrappers/LevelTypeDropdown";

const MemberSearchFilter = ({
  classes,
  requestResultGroup,
  onReset,
  onSubmit,
}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  useEffect(() => {
    dispatch(setWholeState(requestResultGroup))
  }, [requestResultGroup])
  return (
    <div
      className={classNames(styles.filter, classes, {
        [styles.isCollapse]: state.isCollapse,
      })}
    >
      <div className={styles.header} onClick={() => dispatch(toggleCollapse())}>
        <p className={styles.caption}>All Filters</p>
        <div className={styles.collapseIcon}>
          <ArrowTopIcon />
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.body}>
        <div className={styles.block}>
            <RadioButton
              className={styles.radioBtn}
              label="Companies/Organizations"
              checked={state.values.accountType === "company"}
              onClick={() => dispatch(openCompanySubForm())}
            />
            <div
                className={classNames(styles.subForm, {
                  [styles.opened]: state.values.accountType === "company",
                })}
            >
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <CompanyTypeDropdown
                    selectedType={state.values.companyType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <CompanyRoleTypeDropdown
                    selectedType={state.values.companyRoleType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <LevelTypeDropdown
                    selectedType={state.values.levelType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoRight,
                  )}
              >
                <CompanyActivityTypeDropdown
                    companyRoleType={state.values.companyRoleType}
                    selectedType={state.values.companyActivityType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <CompanyIndustryTypeDropdown
                    selectedType={state.values.companyIndustryType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoRight,
                  )}
              >
                <CompanyDiverseTypeDropdown
                    selectedType={state.values.companyDiverseType}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <CompanyCertifiedTypeDropdown
                    selectedType={state.values.companyCertified}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <CompanyClassificationMultiSelectDropdown
                    selectedType={state.values.companyClassification}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoRight,
                  )}
              >
                <CompanyMarketTypeDropdown
                    selectedType={state.values.companyMarket}
                    externalDispatch={dispatch}
                    searchType="company"
                />
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <RadioButton
              className={styles.radioBtn}
              label="Professionals"
              checked={state.values.accountType === "professional"}
              onClick={() => dispatch(openIndividualSubForm())}
            />
            <div
                className={classNames(styles.subForm, {
                  [styles.opened]: (state.values.accountType === "professional"),
                })}
            >
              <div className={styles.item}>
                <ProfessionDropdown
                    selectedType={state.values.professionType}
                    externalDispatch={dispatch}
                />
              </div>
              {state.values.accountType !== "professional" && (
                <div
                    className={classNames(
                        styles.item,
                        styles.halfOfWidth,
                        styles.pushtoLeft,
                    )}
                >
                  <RoleTypeDropdown
                      selectedType={state.values.roleType}
                      externalDispatch={dispatch}
                  />
                </div>
              )}
              {state.values.accountType === "professional" && (
                  <div
                      className={classNames(
                          styles.item,
                          styles.halfOfWidth,
                          styles.pushtoLeft,
                      )}
                  >
                    <JobTitleAutocomplete
                        selectedType={state.values.jobTitle}
                        externalDispatch={dispatch}
                    />
                  </div>
              )}
              <div className={styles.item}>
                <IndustryDropdown
                    selectedType={state.values.industryType}
                    externalDispatch={dispatch}
                />
              </div>
              <div className={styles.item}>
                <ExperienceDropdown
                    selectedType={state.values.experienceLevel}
                    externalDispatch={dispatch}
                />
              </div>
              <div
                  className={classNames(
                      styles.item,
                      styles.halfOfWidth,
                      styles.pushtoLeft,
                  )}
              >
                <GenderTypeDropdown
                    selectedType={state.values.genderType}
                    externalDispatch={dispatch}
                />
              </div>
              <div className={styles.item}>
                <CompanyMarketTypeDropdown
                    selectedType={state.values.markets}
                    externalDispatch={dispatch}
                    searchType="professional"
                />
              </div>
            </div>
          </div>
          {features.upworkIntegration && (
              <div className={styles.block}>
                <RadioButton
                    className={styles.radioBtn}
                    label="Freelancers"
                    checked={state.values.accountType === "freelancers"}
                    onClick={() => dispatch(openFreelancersSubForm())}
                />
                <div
                className={classNames(styles.subForm, {
                  [styles.opened]: state.values.accountType === "freelancers",
                })}
              >
                <div
                  className={classNames(
                    styles.item,
                    styles.halfOfWidth,
                    styles.pushtoLeft,
                  )}
                >
                  <CategoryTypeDropdown
                    selectedType={state.values.categoryType}
                    externalDispatch={dispatch}
                  />
                </div>
                <div
                  className={classNames(
                    styles.item,
                    styles.halfOfWidth,
                    styles.pushtoLeft,
                  )}
                >
                  <FreelancersCountryDropdown
                    selectedType={state.values.freelancersCountry}
                    externalDispatch={dispatch}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
              buttonStyle="outlined"
              className={styles.btn}
              onClick={() => {
                dispatch(resetForm())
                onReset()
              }}
          >
            Clear
          </Button>
          <Button className={styles.btn} onClick={() => onSubmit(state.values)}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
export default MemberSearchFilter
