import React, { useState, useEffect } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Input from "../../../../Input/Input"
import { setJobTitle, setJobTitleInput } from "../../useReducer/actionCreators"
import suggestionServices from "../../../../../services/suggestionServices"
import {jobTitleOptions} from "../../../../CompanyUserInfo/options/jobTitleOptions";

const JobTitleAutocomplete = ({ jobTitleInput, externalDispatch }) => {
  return (
    <Autocomplete
      inputValue={jobTitleInput}
      options={jobTitleOptions.map(option => option.value)}
      renderInput={(params) => {
        return (
          <div ref={params.InputProps.ref}>
            <Input
              type="text"
              label="Title"
              placeholder="Enter title"
              {...params.inputProps}
            />
          </div>
        )
      }}
      onInputChange={(e, value) => {
        externalDispatch(setJobTitleInput(value.substring(0, 64)))
      }}
      onChange={(e, jobTitle, reason) => {
        if (reason === "select-option") {
          externalDispatch(setJobTitle(jobTitle))
        }
      }}
    />
  )
}
export default JobTitleAutocomplete
