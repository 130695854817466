
export const jobTitleOptions = [
  { id: 1, value: "Manager", databaseName: "1" },
  { id: 2, value: "Senior Manager", databaseName: "2" },
  { id: 3, value: "Chief Executive Officer", databaseName: "3" },
  { id: 4, value: "Chief Financial Officer", databaseName: "4" },
  { id: 5, value: "Chief Scientific Officer", databaseName: "5" },
  { id: 6, value: "Chief Operating Officer", databaseName: "6" },
  { id: 7, value: "Chief Development Officer", databaseName: "7" },
  { id: 8, value: "Chief Lending Officer", databaseName: "8" },
  { id: 9, value: "Officer", databaseName: "9" },
  { id: 10, value: "Board Member", databaseName: "10" },
  { id: 11, value: "Chairman", databaseName: "11" },
  { id: 12, value: "Chancellor", databaseName: "12" },
  { id: 13, value: "Associate", databaseName: "13" },
  { id: 14, value: "Director", databaseName: "14" },
  { id: 15, value: "Owner", databaseName: "15" },
  { id: 16, value: "Executive", databaseName: "16" },
  { id: 17, value: "Partner", databaseName: "17" },
  { id: 18, value: "Developer", databaseName: "18" },
  { id: 19, value: "Administrator", databaseName: "19" },
  { id: 20, value: "Mayor", databaseName: "20" },
  { id: 21, value: "President", databaseName: "21" },
  { id: 22, value: "Vice President", databaseName: "22" },
  { id: 23, value: "Physician", databaseName: "23" },
  { id: 24, value: "Superintendent", databaseName: "24" },
  { id: 25, value: "Coach", databaseName: "25" },
  { id: 26, value: "Teacher", databaseName: "26" },
  { id: 27, value: "Lead", databaseName: "27" },
  { id: 28, value: "Judge", databaseName: "28" },
  { id: 29, value: "Other", databaseName: "29" },
]
